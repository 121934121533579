﻿/// <reference path="../../../main">

.resources {
    &-list {
        &-item {
            padding: $gap-xs;
            border: 1px solid $c4;
            display: block;

            &:first-child ~ * {
                border-top-width: 0;
            }
        }
    }

    &-link {
        display: block;
    }
}
