﻿/// <reference path="../../../main">

$_modal-max-width: 64rem;
$_modal-overlay-color: rgba($base-text, 0.6);
$_modal-background-color: $true-white;
$_modal-close-top-offset: $gap-md;
$_modal-horizontal-spacing: $standard-padding-map;
$_modal-vertical-spacing: $vertical-padding-map;

// STRUCTURE & LAYOUT
.modal {
    &-container {
        &[aria-hidden="true"] {
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            display: none;
        }

        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        opacity: 1;
        display: flex;
        justify-content: center;
    }

    &-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        &::after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
        }
    }

    &-dialog {
        position: relative;
        width: 100%;
        max-width: $_modal-max-width;
        margin-left: auto;
        margin-right: auto;
        pointer-events: none;
        margin-top: 0;
    }

    &-inner {
        position: relative;
        max-height: 100%;
        overflow: auto;
    }
}

// STYLING
.modal {
    &-container {
        @include responsive-context($_modal-horizontal-spacing, $_modal-vertical-spacing) {
            padding: $responsive-variable-2 $responsive-variable;

            .modal-close {
                top: -$responsive-variable-2 / 2 - $space;
            }
        }

        background-color: $_modal-overlay-color;
        transition: 0.3s opacity;
    }

    &-inner {
        @include responsive-context($_modal-horizontal-spacing, $_modal-vertical-spacing) {
            padding: $responsive-variable-2 $responsive-variable;
        }

        background-color: $_modal-background-color;
        pointer-events: auto;

        &-full-bleed {
            padding: 0;
        }
    }
}

// CLOSE BUTTON
.modal {
    &-close {
        right: 0;
        pointer-events: auto;
        position: absolute;
        width: hypotenuse($ui-icon-size, $ui-icon-size);
        height: hypotenuse($ui-icon-size, $ui-icon-size);
        min-width: 0;
        display: inline-block;
        line-height: 1;
        background: transparent;
        border: 0;

        &::before,
        &::after {
            content: '';
            display: block;
            width: hypotenuse($ui-icon-size, $ui-icon-size);
            height: $ui-icon-thickness;
            background: $true-white;
            position: absolute;
            left: 0;
            top: calc(50% - #{$ui-icon-thickness / 2});
        }

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }
}
