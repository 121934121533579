﻿/// <reference path="../../../main">

%pre-focus-styles {
    outline: 2px solid transparent;
    outline-offset: 3px;
    transition: $transition all;
}

%focus-styles {
    box-shadow: 0 0 0 2px $true-white;
    outline-offset: 2px;
    outline: 2px solid $base-text;
}

@mixin focus() {
    @extend %pre-focus-styles;

    &:focus, &:active, &:focus:active {
        @extend %focus-styles;
    }
}

%focus {
    @include focus();
}

a,
area,
input,
select,
textarea,
button,
iframe {
    @include focus();
}