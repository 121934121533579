﻿/// <reference path="../../../main">
// return width by number of columns within the 12 column layout (assuming consistent gutter)

@mixin column-width($num: 1, $overflow-handled: false) {
    $_gutter-difference-total: (12 - $num) / 12 * $max-column-gap;
    $_percentage-total: $num * (1/12) * 100%;

    @if $overflow-handled is true {
        width: calc(#{$_percentage-total} - #{$max-column-gap});
    }
    @else {
        width: calc(#{$_percentage-total} - #{$_gutter-difference-total});
    }
}