﻿/// <reference path="../../../main">

$_accordion-caret-size: 0.5rem;
$_accordion-caret-thickness: 2px;
$_accordion-transition-length: $transition;
$_accordion-trigger-padding: 0.75rem;
$_accordion-trigger-text-color: $base-text;
$_accordion-divider-color: $c4;
$_accordion-panel-padding: $gap-xs;

// STRUCTURE
.accordion {
    &-panel {
        overflow: hidden;

        &[aria-hidden="true"] {
            height: 0 !important;
        }
    }
}


// STYLES
.accordion {
    margin-left: auto;
    margin-right: auto;

    &-inner {
        padding-left: 0;
        max-width: $max-line-length;
    }

    &-heading {
        color: $_accordion-trigger-text-color;
    }

    &-trigger {
        background: transparent;
        display: block;
        padding-top: $_accordion-trigger-padding;
        padding-bottom: $_accordion-trigger-padding;
        padding-left: $_accordion-trigger-padding;
        text-align: left;
        width: 100%;
        border: 1px solid $_accordion-divider-color;
        text-transform: none;
        color: $_accordion-trigger-text-color;

        &:focus {
            outline-color: rgba($c2, 0.5);
            outline-style: dotted;
            outline-width: 1px;
        }
    }

    &-item {
        border-color: transparent;
        margin-top: $space;

        &:first-child {
            margin-top: 0;
        }
    }

    &-panel {
        transition: $_accordion-transition-length height;

        &-content {
            padding: $_accordion-panel-padding;
            border: 1px solid $_accordion-divider-color;
            margin-top: -1px;
        }
    }
}


// ARROW/INDICATOR
.accordion {
    &-trigger {
        position: relative;
        padding-right: $_accordion-caret-size * 4;

        &::before {
            content: '';
            width: 0;
            background-color: $c1;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            transition: $transition width;
        }

        &::after {
            content: '';
            width: $_accordion-caret-size;
            height: $_accordion-caret-size;
            display: block;
            position: absolute;
            right: $_accordion-trigger-padding;
            top: calc(50% - #{$_accordion-caret-size / 2});
            transform: rotate(45deg);
            transition: $_accordion-transition-length transform;
            border-color: $c1;
            border-style: solid;
            border-width: 0 $_accordion-caret-thickness $_accordion-caret-thickness 0;
        }

        &:hover {
            &::after {
                border-color: $_accordion-trigger-text-color;
            }
        }

        &[aria-expanded="true"] {
            &::before {
                width: 5px;
            }

            &::after {
                transform: rotate(-135deg);
            }
        }
    }
}