﻿/// <reference path="../../../main">

.footer {
    background: $true-black;

    &, p {
        @extend %body;
    }

    --text-margin: #{$medium-text-margin};

    &-main {
        @extend %dark;
        display: flex;

        @include responsive-context($standard-padding-map, $vertical-padding-map, "true") {
            padding: $responsive-variable-2 $responsive-variable;
        }

        & > * {
            width: 33%;
        }

        @include media(small) {
            display: block;
        }

        @supports(display: grid) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: $gap;

            & > * {
                width: auto;
            }

            @include media-range(small, medium) {
                grid-template-columns: repeat(2, 1fr);
            }

            @include media(small) {
                display: grid;
                grid-template-columns: 1fr;
            }
        }
    }

    &-item {
        & > * + * {
            margin-top: $gap;
        }
    }

    &-social-list {
        display: flex;

        & > * {
            flex: 0 0 auto;
            margin-right: $gap-xs;
            font-size: $_font-size-default-x-small;
            font-size: var(--responsive-regular);
        }
    }

    &-register {
        &-info {
            --text-margin: 1em;
        }
    }

    &-legal {
        padding-top: $gap-xs;
        padding-bottom: $gap-xs;
        background: $c4;
        display: flex;
        justify-content: space-between;

        @include responsive-context($standard-padding-map) {
            padding-left: $responsive-variable;
            padding-right: $responsive-variable;
        }

        & > *:first-child {
            margin-right: $gap-xs;
        }

        a {
            @include link-helper($true-black, $c1);
        }

        p {
            @extend %caption;

            @include media-min(small) {
                margin-top: 0;
            }
        }

        @include media(small) {
            display: block;
        }
    }
}