﻿/// <reference path="../../../../main">

.ribbon {
    @include responsive-context($standard-padding-map) {
        padding-left: $responsive-variable;
        padding-right: $responsive-variable;
    }

    --text-margin: #{$medium-text-margin};

    border-bottom: 1px solid $c4;
    padding-top: $space;
    padding-bottom: $space;
    
    &-list {
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style-type: none;
        margin: 0;

        @include media(small) {
            display: block;
        }

        &-item {
            @extend %h5;
            flex: 1 0 0;
            padding: $space;
            text-align: center;
            margin: 0;
        }
    }
}
