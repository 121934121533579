﻿/// <reference path="../../../main">

header .site-search {
    position: static;
    width: auto;
    max-width: 25%;

    @include media(medium) {
        max-width: none;
        margin-top: $gap-sm;
        margin-bottom: $gap;
    }

    form {
        width: 100%;
    }

    .form-control {
        max-width: none;
    }
}

.site-search {
    width: auto;
    display: flex;
    align-items: center;
    margin: 0;

    fieldset {
        margin: 0;
    }

    .btn {
        width: $base-header-height - ($sliver * 2);
        height: $base-header-height - ($sliver * 2);
        position: static;
        font-size: var(--xx-small-font-size);
    }

    .form-group {
        margin: 0;
        width: auto;
        display: flex;
    }

    .form-control {
        min-height: 0;
        height: auto;
        padding-right: 0;
        line-height: 1;
        border-color: $c4;
    }
}