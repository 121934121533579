﻿/// <reference path="../../../main">

body {
    display: flex;
    min-height: 100%;
    height: auto;
}

#site {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;

    main {
        flex: 1 0 auto;
        min-height: 1px;
    }
}

// make sure footer pops above sticky minical
main ~ * {
    position: relative;
}