﻿/// <reference path="../../../main">

$_radiocheck-background: $c5;
$_radiocheck-foreground: $base-text;
$_radiocheck-border: $base-text;
$_radiocheck-size: 1em;
$_radiocheck-padding: 0.5em;
$_radiocheck-top-offset: (1em * $_normal-line-height - $_radiocheck-size) / 2;

*:not(label) > input[type="checkbox"]:not(.umbraco-forms-checkbox),
*:not(label) > input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: 0;
    overflow: hidden;
    opacity: 0;

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

input[type="checkbox"],
input[type="radio"] {
    &:focus {
        & + label,
        & + input[type="hidden"] + label {
            @extend %focus-styles;
        }
    }

    & + label,
    & + input[type="hidden"] + label {
        @extend %pre-focus-styles;
        position: relative;
        display: inline-block;

        &::before {
            content: '';
            border: 1px solid $_radiocheck-border;
            display: inline-block;
            position: absolute;
            left: 0;
            top: $_radiocheck-top-offset;
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::after {
            content: '';
            position: absolute;
            display: inline-block;
        }
    }
}

// SIZING
input[type="radio"],
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        padding-left: $_radiocheck-size + $_radiocheck-padding;

        &::before {
            width: $_radiocheck-size;
            height: $_radiocheck-size;
        }
    }
}


input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            border: 1px solid $_radiocheck-foreground;
            border-width: 0 0 3px 3px;
            transform: rotate(-45deg) scale(0.8);
            display: block;
            top: $_radiocheck-top-offset + 0.15em;
            left: 0.0625em;
        }
    }

    &:checked + label,
    &:checked + input[type="hidden"] + label {
        &::before {
            background-color: $_radiocheck-background;
        }
    }
}

// SIZING
input[type="checkbox"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            width: $_radiocheck-size / 1.125;
            height: $_radiocheck-size / 2;
        }
    }
}


input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::before {
            border-radius: 50%;
        }

        &::after {
            background: $_radiocheck-foreground;
            border: 2px solid transparent;
            border-radius: 50%;
            transform: scale(0.5);
            top: $_radiocheck-top-offset;
            left: 0;
        }
    }
}

// SIZING
input[type="radio"] {
    & + label,
    & + input[type="hidden"] + label {
        &::after {
            width: $_radiocheck-size;
            height: $_radiocheck-size;
        }
    }
}