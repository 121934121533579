/// <reference path="../../main">

// ------------- MEDIA QUERIES ------------- //
$v-large-media-query-size: 1199px;
$large-media-query-size: 991px;
$medium-media-query-size: 767px;
$small-media-query-size: 599px;
$mobile-media-query-size: 479px;

// ------------- FONTS ------------- //

$body-font:		'Muli', Helvetica, Arial, sans-serif;
$heading-font:  $body-font;

// ------------- FONT WEIGHTS ------------- //

$body-font-weight-normal:		400;
$body-font-weight-bold:			800;
$heading-font-weight-light:     400;
$heading-font-weight-bold:      800;

// ------------- CONSTANTS ------------- //

$gap-lg:                    4rem;
$gap-md:                    2.5rem;
$gap:                       2rem;
$gap-sm-md:                 1.75rem;
$gap-sm:                    1.25rem;
$gap-xs:                    1rem;
$space:                     0.5rem;
$sliver:                    0.25rem;
$max-column-gap:            2rem;
$small-text-margin:         0.125em;
$medium-text-margin:        0.25em;
$regular-text-margin:       1em;

// max widths
$max-standard-pad:          4rem;
$max-line-length:           60ch;
$max-input-width:           30rem;
$max-total-width:           1600px;
$max-content-width:         $max-total-width - ($max-standard-pad / 1rem * 16px * 2);
$max-total-width-plus-padding: $max-total-width + ($max-standard-pad / 1rem * 16px * 2);

$breakpoints: (
    max-total-width: $max-total-width-plus-padding,
    v-large: $v-large-media-query-size,
    large : $large-media-query-size,
    medium: $medium-media-query-size,
    small : $small-media-query-size,
    mobile : $mobile-media-query-size
);

// UI elements
$ui-icon-thickness: 2px; //for pseudoelement arrows, close buttons, etc
$border-radius:             0;
$button-size:               3rem; //for gallery navs, etc
$ui-icon-size:              0.75rem;
$button-min-width:          8rem;
$disabled-opacity:          0.4;
$svg-icon-size:             1.5rem;

// ------------- NAVIGATION ------------------ //
$logo-width: 5.75rem;
$mobile-nav-height: 3.75rem;
$sticky-nav-height: 5rem;

// ------------- MISCELLANEOUS --------------- //

$prefix:                    'adage-'; //prefix for project-specific classes
$transition:                0.3s;
$one-third:                 (1/3 * 100%) - 0.01% !global;
$one-twelvth:               (1/12 * 100%);

// ------------- PADDING/MARGIN MAPS --------- //

// It's best if all these maps contain the same breakpoint values!!

$standard-padding-map: ( // for global use on most elements. Extend %standard-padding-h
        null        : $max-standard-pad,
        max-total-width : $max-standard-pad,
        v-large     : 3rem,
        large       : 2rem,
        medium      : 2rem,
        small       : 1rem
    );

$vertical-padding-map: (
        null        : 4rem,
    max-total-width : 4rem,
        v-large     : 3rem,
        large       : 2rem,
        medium      : 2rem,
        small       : 2rem
    );

$gutter-map: ( // booo bootstrap
        null            : $gap,
        max-total-width : $gap,
        v-large         : $gap,
        large           : $gap,
        medium          : $gap,
        small           : 1rem
    );

$heading-spacing-map: (
        null            : 2.5rem,
        v-large         : 2rem,
        large           : 1rem,
        small           : 1rem
    )