﻿/// <reference path="../../../main">

.sticky-nav {
    position: fixed;
    top: -$sticky-nav-height;
    left: 0;
    right: 0;
    height: $sticky-nav-height;
    z-index: 900;
    background: $c4;

    @include media-range($medium-media-query-size, 1010px) {
        top: calc(var(--nav-clearance) - #{$sticky-nav-height});
    }

    &-desktop {
        z-index: 2;

        @include media(small) {
            @include hide;
        }
    }

    &-all-screens {
        z-index: 12;
    }

    &-inner {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-background {
        @extend %full-cover;

        & ~ * {
            position: relative;
        }
    }
}

// show/hide
.sticky-nav {
    transition: $transition all linear;

    &[aria-hidden="false"] {
        transform: translateY(#{$sticky-nav-height + $base-header-height});

        @include media(medium) {
            transform: translateY(#{$sticky-nav-height + $mobile-nav-height});
        }

        @include media-range($medium-media-query-size, 1010px) {
            @supports ((--a: 0)) {
                transform: translateY(#{$sticky-nav-height});
            }
        }
    }
}

// Mobile: stuck to page bottom
.sticky-nav-mobile {
    @include media-min(small) {
        @include hide;
    }

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    height: $sticky-nav-height;

    &-info {
        grid-column: left-edge / center;
    }

    &-cta {
        align-self: stretch;
        display: flex;
        grid-column: center / -1;

        & > * {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1;
        }
    }
}

// IE fallback
@include ie {
    .sticky-nav {
        &-inner {
            align-items: center;
            justify-content: space-between;
            margin-top: $gap-xs;

            @include media(medium) {
                display: flex;
            }

            & > * {
                margin: 0;
                flex: 0 1 auto;

                @include media-min(medium) {
                    width: 24%;
                }

                &:last-child {
                    margin-right: 0;
                    margin-left: auto;
                    text-align: right;

                    @include media-min(medium) {
                        flex-grow: 1;
                        width: 74%;
                    }
                }
            }
        }

        &-info {
            float: left;
            margin-top: $gap-xs;
        }

        &-background {
            width: 100%;
        }

        &-mobile {
            .sticky-nav-inner {
                margin-top: 0;
            }

            &-info {
                @include media-min(medium) {
                    float: left;
                    margin-top: $gap-xs;
                }
            }

            &-cta {
                height: 100%;
                width: 50%;
                margin-right: -$gap;
            }
        }
    }
}
