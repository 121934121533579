﻿/// <reference path="../../../main">

.umbraco-forms {
    &-row {
        display: flex;
        justify-content: space-between;
        margin-top: 0;

        @include media(medium) {
            display: block;
        }

        & > * {
            @extend %top-margin;
        }
    }

    &-container {
        flex: 0 0 auto;
    }

    &-field {
        &.checkbox {
            margin: 0;

            label {
                padding: 0;
                min-height: 0;
            }
        }

        & + &,
        & + &.checkbox {
            @include responsive-context($vertical-padding-map) {
                margin-top: $responsive-variable / 2;
            }
        }
    }

    &-checkbox[type="checkbox"]:nth-child(n) {
        position: static;
        margin: 0;
    }

    &-indicator {
        color: $red;
    }

    &-label {
        & + * {
            margin-top: $space;
        }
    }

    &-navigation {
        @extend %top-margin;
        padding-bottom: $gap-sm;
    }

    &-fieldset {
        flex: 1 1 auto;
        width: 100%;
    }
}

.radiobuttonlist {
    > label {
        display: block;
        position: relative;
    }
}