﻿/// <reference path="../../../../main">

.pdp {
    &-hero {
        min-height: 0;

        &-bg {
            opacity: 1;
        }

        &-content {
            min-height: calc(50vh - #{$base-nav-main-height_sm});
            align-items: flex-end;
            justify-content: flex-start;

            @include media(medium) {
                min-height: calc(50vh - #{$mobile-nav-height});
            }

            @include media(small) {
                flex-direction: column;
                align-items: stretch;
                justify-content: flex-end;
            }

            &-inner {
                pointer-events: auto;
            }
        }

        &-share {
            position: relative;
            margin-right: 0;
            margin-left: auto;

            @include media(small) {
                margin-left: 0;
                margin-right: auto;
                margin-top: $gap-xs;
            }

            &-links {
                display: flex;
                align-items: center;
                line-height: 1;

                & > * {
                    margin-left: $gap-xs;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
