﻿/// <reference path="../../../main">
.field-validation-error {
    @extend %top-margin;
    display: inline-block;
    color: $base-text;
    border: 1px solid $red;
    position: relative;

    &::before {
        content: '!';
        vertical-align: middle;
        margin-right: $space;
        line-height: 1;
        border: 1px solid $red;
        color: $red;
        border-radius: 50%;
        width: $svg-icon-size;
        height: $svg-icon-size;
        font-weight: $body-font-weight-bold;
        display: inline-block;
        text-align: center;
        padding-top: 0.175rem;
    }

    &::after {
        content: '';
        width: $ui-icon-size;
        height: $ui-icon-size;
        display: block;
        position: absolute;
        top: 0;
        left: $gap-xs;
        border: 1px solid $red;
        border-width: 1px 1px 0 0;
        transform: translateY(-52%) rotate(-45deg);
        background: $true-white;
    }
}