/// <reference path="../../main">

/* 	
--------------------------------------------------
uSkinned.net
PALETTE
-------------------------------------------------- 
*/


/* 	
--------------------------------------------------
TABLE OF CONTENTS
-------------------------------------------------- 

1.0 BACKGROUNDS
2.0 TEXT
3.0 BORDERS
4.0 BUTTONS
	
*/


/*	
--------------------------------------------------
1.0 BACKGROUNDS
-------------------------------------------------- 
*/

$usn-colors: ( "1": $c1, "2": $c2, "3": $c3, "4": $c4, "5": $c5, "6": $c6 );

@each $i, $c in $usn-colors {
    .c#{$i}-bg {
        background-color: rgba($c, 1);
    }

    .btn.c#{$i}-bg:hover,
    .btn.c#{$i}-bg:focus,
    .btn.c#{$i}-bg:active {
        background-color: darken($c, 10%);

        &[disabled], &.disabled {
            background-color: $c;
        }
    }

    .c#{$i}-text {
        color: $c;

        svg {
            fill: $c;
        }
    }

    a.c#{$i}-text,
    .btn.c#{$i}-text {
        color: $c;

        svg {
            fill: $c;
        }

        &:hover, &:focus, &:active {
            color: darken($c, 10%);

            svg {
                fill: darken($c, 10%);
            }
        }
    }

    .btn.c#{$i}-text {
        &[disabled], &.disabled {
            color: $c;

            svg {
                fill: $c;
            }
        }
    }

    .c#{$i}-borders {
        border-color: $c;
    }


    @for $n from 1 through 9 {
        .c#{$i}-bg.bg-perc_#{$n * 10} {
            background-color: rgba($c, ($n / 10));
        }

        .c#{$i}-text.text-perc_#{$n * 10} {
            color: rgba($c, ($n / 10));
        }

        .c#{$i}-borders.borders-perc_#{$n * 10} {
            border-color: rgba($c, ($n / 10));
        }
    }
}

html.no-touch .apc.pod-windows .item a.info.c1-bg:hover::before,
html.no-touch .apc.pod-windows .item a.info.c1-bg:focus::before {
    background-color: rgba($c1, 0.8);
}

.apc.banner .item.overlay.c1-bg::before,
.apc.pod-windows .item.overlay .info.c1-bg::before {
    background-color: rgba($c1, 0.5);
}

html.no-touch .apc.pod-windows .item a.info.c2-bg:hover::before,
html.no-touch .apc.pod-windows .item a.info.c2-bg:focus::before {
    background-color: rgba($c2, 0.8);
}

.apc.banner .item.overlay.c2-bg::before,
.apc.pod-windows .item.overlay .info.c2-bg::before {
    background-color: rgba($c2, 0.5);
}

html.no-touch .apc.pod-windows .item a.info.c3-bg:hover::before,
html.no-touch .apc.pod-windows .item a.info.c3-bg:focus::before {
    background-color: rgba($c3, 0.8);
}

.apc.banner .item.overlay.c3-bg::before,
.apc.pod-windows .item.overlay .info.c3-bg::before {
    background-color: rgba($c3, 0.5);
}

html.no-touch .apc.pod-windows .item a.info.c4-bg:hover::before,
html.no-touch .apc.pod-windows .item a.info.c4-bg:focus::before {
    background-color: rgba($c4, 0.8);
}

.apc.banner .item.overlay.c4-bg::before,
.apc.pod-windows .item.overlay .info.c4-bg::before {
    background-color: rgba($c4, 0.5);
}

#site {
    background-color: rgba($c5, 1);
}

html.no-touch .apc.pod-windows .item a.info.c5-bg:hover::before,
html.no-touch .apc.pod-windows .item a.info.c5-bg:focus::before {
    background-color: rgba($c5, 0.8);
}

.apc.banner .item.overlay.c5-bg::before,
.apc.pod-windows .item.overlay .info.c5-bg::before {
    background-color: rgba($c5, 0.5);
}

html.no-touch .apc.pod-windows .item a.info.c6-bg:hover::before,
html.no-touch .apc.pod-windows .item a.info.c6-bg:focus::before {
    background-color: rgba($c6, 0.8);
}

.apc.banner .item.overlay.c6-bg::before,
.apc.pod-windows .item.overlay .info.c6-bg::before {
    background-color: rgba($c6, 0.5);
}

.modal-backdrop,
.modal-backdrop.in {
    background-color: rgba($true-black, 1);
}


/*	
-------------------------------------------------- 
2.0 TEXT
-------------------------------------------------- 
*/

body,
.base-text {
    color: rgba($base-text, 1);
}

body .c1-bg {
    color: rgba($c5, 1);
}


/* LINKS */

.apc.c1-bg a:not(.btn):not(.heading):not(.c1-text):not(.c3-text) {
    color: rgba($c5, 1);
    font-weight: bold;
}

html.no-touch .c1-bg a:not(.btn):not(.c1-text):not(.c3-text):hover,
.c1-bg a:not(.btn):not(.c1-text):not(.c3-text):active,
.c1-bg a:not(.btn):not(.c1-text):not(.c3-text):focus {
    color: darken($c5, 10%);
}


/* HEADINGS */

.c1-bg h1:not(.c1-text):not(.c3-text),
.c1-bg h2:not(.c1-text):not(.c3-text),
.c1-bg h3:not(.c1-text):not(.c3-text),
.c1-bg h4:not(.c1-text):not(.c3-text),
.c1-bg h5:not(.c1-text):not(.c3-text),
.c1-bg h6:not(.c1-text):not(.c3-text),
.c1-bg .heading:not(.c1-text):not(.c3-text),
.c1-bg .secondary-heading:not(.c1-text):not(.c3-text),
.c3-bg h1:not(.c1-text):not(.c3-text),
.c3-bg h2:not(.c1-text):not(.c3-text),
.c3-bg h3:not(.c1-text):not(.c3-text),
.c3-bg h4:not(.c1-text):not(.c3-text),
.c3-bg h5:not(.c1-text):not(.c3-text),
.c3-bg h6:not(.c1-text):not(.c3-text),
.c3-bg .heading:not(.c1-text):not(.c3-text),
.c3-bg .secondary-heading:not(.c1-text):not(.c3-text) {
    color: rgba($c5, 1);
}


/*	
--------------------------------------------------
5.0 LINKS
-------------------------------------------------- 
*/

html.no-touch .link.text-uppercase .c1-borders:hover,
.link.text-uppercase .btn.c1-borders:active,
.link.text-uppercase .btn.c1-borders:focus,
html.no-touch .link.text-uppercase .c2-borders:hover,
.link.text-uppercase .btn.c2-borders:active,
.link.text-uppercase .btn.c2-borders:focus,
html.no-touch .link.text-uppercase .c4-borders:hover,
.link.text-uppercase .btn.c4-borders:active,
.link.text-uppercase .btn.c4-borders:focus,
html.no-touch .link.text-uppercase .c5-borders:hover,
.link.text-uppercase .btn.c5-borders:active,
.link.text-uppercase .btn.c5-borders:focus {
    border-color: rgba($c3, 1);
}

html.no-touch .link.text-uppercase .c3-borders:hover,
.link.text-uppercase .btn.c3-borders:active,
.link.text-uppercase .btn.c3-borders:focus {
    border-color: rgba($c3, 1);
}


/*	
-------------------------------------------------- 
END
-------------------------------------------------- 
*/
