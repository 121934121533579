﻿/// <reference path="../../../../main">

.standard {
    &-page-container {
        @extend %max-total-width;
        @extend %standard-padding-h;

        @include media-min(large) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &-content {
        @include column-width(9);

        &-center, &-centeralt {
            @include column-width(6);
        }

        &-centeralt {
            margin-left: $max-column-gap;
            margin-right: auto;
        }

        &-center {
            margin-right: $max-column-gap;
            margin-left: auto;
        }

        &-wide {
            width: 100%;
        }
    }

    &-aside {
        @include column-width(3);
        margin-left: 0;
        margin-right: 0;
    }

    &-content,
    &-aside {
        flex: 0 0 auto;

        @include media(large) {
            width: 100%;
        }
    }
}

// alignment
.standard {
    &-content {
        &-left, &-right {
            .center-column {
                margin-left: 0;
            }
        }

        &-center, &-centeralt {
            .center-column {
                width: 100%;
            }
        }
    }

    &-aside {
        &-left, &-centeralt {
            &:first-child {
                @include media(large) {
                    display: none;
                }
            }

            &:last-child {
                @include media-min(large) {
                    display: none;
                }
            }
        }

        &-right, &-center {
            &:first-child {
                display: none;
            }
        }
    }
}

@import './_standard-subnav.scss';
