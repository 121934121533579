﻿/// <reference path="../../../main">

nav.top {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $base-header-height;

    @include media(medium) {
        display: block;
        height: auto;
    }
}

.secondary-nav {
    &-ul0 {
        display: flex;
    }

    &-li0 {
        display: flex;
        align-items: center;
        margin-right: $gap;
    }

    &-link {
        font-size: var(--xx-small-font-size);
        @include link-helper($true-white, $c4);
    }

    @include media(medium) {
        &-ul0 {
            display: block;
        }

        &-li0 {
            margin-top: $gap-xs;
            margin-right: 0;

            &[data-nav-link-calendar] {
                display: none;
            }
        }
    }
}