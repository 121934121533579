﻿/// <reference path="../../../main">

.navigation {
    nav.main {
        background: $true-white;
        top: $base-header-height;
        right: 0;
        left: 0;
        border-bottom: 1px solid $c4;
        font-size: $_font-size-default-regular;
        font-size: var(--navigation-font-size);
    }

    a {
        text-decoration: none;
    }
}

nav.main, nav.top {
    display: flex;
    justify-content: flex-end;

    @include responsive-context($standard-padding-map) {
        padding-right: $responsive-variable;
        padding-left: $responsive-variable;
    }

    @include media-min(medium) {
        padding-left: $logo-width;
    }
}

// NEW DROPDOWN STYLES
.main-nav {
    &-ul0 {
        display: flex;
        justify-content: flex-end;

        @include media-min(medium) {
            width: 100%;
            margin-left: $gap-sm;
        }
    }

    &-li0 {
        display: flex;
        align-items: center;
        padding: 0 $space;
        position: relative;
        flex: 0 1 auto;
        font-size: var(--navigation-font-size);

        @include media-range(medium, 820px) {
            flex: 1 1 auto;
        }

        &:last-child {
            padding-right: 0;
        }

        &.has-child {
            &::after {
                content: '';
                width: $base-nav-main-dropdown-width_sm;
                position: absolute;
                top: 100%;
                left: 0;
                height: 2px;
                display: block;
                z-index: 2002;
            }

            &:last-of-type {
                &::after {
                    right: 0;
                    left: auto;
                }
            }

            &:focus, &:hover {
                color: $c1;

                i {
                    color: $c1;

                    @include media-min(medium) {
                        &::before {
                            transform: rotate(-180deg);
                        }
                    }
                }

                &::after {
                    background: $c1;
                }
            }
        }

        &-button, &-text {
            display: flex;
            align-items: center;
            font-weight: $body-font-weight-bold;

            span {
                flex: 1 1 auto;

                @include media-range(medium, 820px) {
                    width: min-content;
                }
            }

            i {
                flex: 0 0 auto;

                &::before {
                    transition: $transition all;
                    transform-origin: center center;
                }
            }
        }

        &-text {
            cursor: default;
            text-align: center;

            i {
                font-size: 75%;
                padding: $space;
                padding-right: 0;
            }
        }

        &-button + &-text {
            @include media(medium) {
                display: none;
            }
        }

        &-button {
            background: transparent;
            border: 0;
            width: 100%;

            @include media-min(medium) {
                display: none;
            }

            &:focus, &:active, &:focus:active {
                outline-color: transparent;
                box-shadow: none;
            }
        }
    }

    &-ul1 {
        transition: 0.5s height .25s;
        left: 0;
        overflow: hidden;
        background: $true-white;

        @include media(medium) {
            background: transparent;
        }
    }

    &-li0:last-of-type &-ul1 {
        left: auto;
        right: 0;
    }

    @include media-min(medium) {
        &-li0.has-child:not(:hover):not(:focus) &-ul1 {
            height: 0 !important;
        }

        &-li0.has-child:focus-within &-ul1:nth-child(n) {
            // must be importanter than the above but can't be in the same statement because focus-within breaks an entire CSS rule in some browsers
            height: auto !important;
        }
    }

    &-li1 {
        font-size: var(--responsive-h6);

        &-link {
            padding: $space;

            @include link-helper($base-text, $c1);
        }
    }
}

body:not(.no-header) {
    padding-top: $base-header-height * 2;

    @include media(medium) {
        padding-top: $mobile-nav-height;
    }
}

html {
    --nav-clearance: #{$base-header-height * 2};
}

.nav-up {
    @include media-min(medium) {
        --nav-clearance: #{$base-header-height};
    }
}

@include media-min(medium) {
    --nav-clearance: #{$mobile-nav-height};

    &.sticky-nav-open {
        --nav-clearance: #{$sticky-nav-height};
    }
}