﻿/// <reference path="../../../main">

@mixin link-helper($color: $base-text, $contrast: $c1) {
    transition: $transition color, $transition opacity, $transition outline, $transition box-shadow, $transition transform, $transition background;
    color: $color;

    svg {
        transition: $transition;
        fill: $color;
    }

    &:hover,
    &:active,
    &:focus {
        color: $contrast;

        svg {
            fill: $contrast;
        }
    }
}