﻿/// <reference path="../../../main">

[data-sticky-element] {
    position: relative;
    transition: $transition;
    will-change: transform;

    @include ie {
        backface-visibility: hidden;
        transform: translateZ(1px);
        transition: 0;
    }

    &.sticky-element-locked {
        position: fixed;

        @include responsive-context($standard-padding-map) {
            left: $responsive-variable;
        }

        @include media-min($max-total-width-plus-padding) {
            left: calc(50% - #{$max-total-width / 2});
        }
    }

    &.sticky-element-free {
        top: auto !important;

        & + .sticky-placeholder {
            height: 0 !important;
        }
    }
}

body .sticky-element-locked {
    top: var(--nav-clearance) !important;
}
