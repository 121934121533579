﻿/// <reference path="../../../main.scss" />

// FORM STRUCTURE
fieldset {
    display: block;
}

// an input and submit button that are inline, with either an <a> or <button>
.inline-input-submit {
    display: flex;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    & > * + *:nth-child(n) {
        margin-top: 0;
    }

    & > input,
    & > select,
    & > div {
        flex: 1 1 auto;
    }

    & > a,
    & > button {
        flex: 0 0 auto;
        min-width: 0;
    }
}

label > *[class*="btn"] {
    padding: $space;
    margin-top: -$space;
    margin-bottom: -$space;
}

%no-max-width {
    max-width: none;
    width: 100%;
}

.form {
    &-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &-item {
        &,
        &-half {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            fieldset {
                display: block;
                width: 100%;
            }

            label {
                flex: 1 1 auto;
            }
        }

        &-full {
            @include all-form-elements {
                @extend %no-max-width;
            }
        }
    }

    @include responsive-context($gutter-map) {
        &-container {
            margin-right: -$responsive-variable;

            & > * {
                margin-right: $responsive-variable;
            }
        }

        &-item {
            &,
            &-half {
                width: calc(100% - #{$responsive-variable});
                margin-right: $responsive-variable;
                margin-bottom: $responsive-variable;
            }

            &-half {
                width: calc(50% - #{$responsive-variable});
                max-width: (remToPx($max-input-width) / 2) - ($responsive-variable / 2);
            }
        }

        @if $responsive-breakpoint != null and (map-get($breakpoints, $responsive-breakpoint) <= $small-media-query-size) {
            &-item-half {
                width: calc(100% - #{$responsive-variable});
                max-width: none;
            }
        }
    }
}

.form-item-break {
    width: 100%;
    margin: 0;
    border: none;
}

//hints
.label-hint {
    float: right;
    font-weight: $body-font-weight-normal;
}

[data-validation-errors]:not([data-validation-errors="0"]) {
    .form-item,
    .form-item-half {
        label {
            flex: 0 1 auto;
        }
    }
}

.field-validation-error {
    @extend %caption;
    padding: $space;
}

.input-validation-error {

    &:focus {
        box-shadow: 0 0 0 2px $form-errors;
    }
}