﻿/// <reference path="../../../../main">

.standard-subnav {
    &-inner {
        padding: $gap-sm;
        padding-top: 0;
    }

    &-heading {
        padding: $gap-sm;
    }

    &-li {
        margin-top: $gap-sm;
    }

    &-ul &-ul {
        margin-left: $gap-sm;
    }

    &-li &-li {
        &::before {
            content: '-';
        }
    }
}
