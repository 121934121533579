﻿/// <reference path="../../../main">

$_breadcrumb-height: 3rem;

.breadcrumb {
    &-container {
        border-top: 1px solid $c4;
    }

    &-inner {
        @extend %standard-padding-h;
    }

    &-list {
        display: flex;
        overflow: hidden;
        height: $_breadcrumb-height;

        & > li {
            display: flex;
            align-items: center;

            &::after {
                content: '';
                width: hypotenuse($_breadcrumb-height / 2, $_breadcrumb-height / 2);
                height: hypotenuse($_breadcrumb-height / 2, $_breadcrumb-height / 2);
                border: 1px solid $c4;
                display: block;
                transform: scaleX(0.66) rotate(45deg);
                border-width: 1px 1px 0 0;
                margin-left: -$space * 1.5;
                flex: 0 0 auto;
            }

            &:last-child {
                &::after {
                    content: none;
                }
            }

            &:first-child ~ * {
                padding-left: $space * 1.5;
            }
        }
    }
}
